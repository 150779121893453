import React from 'react'
import PropTypes from 'prop-types'
import OrderRecipientTable from '@components/orderrecipienttable'

export const frontmatter = {
  title: 'Bestellung - E-Mail Einstellung',
}

function OrderRecipientPage() {
  return <OrderRecipientTable />
}

OrderRecipientPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default OrderRecipientPage
